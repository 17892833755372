import React from "react"
import styles from "./obnovleniya-banner-end.module.scss"
import { openOverlay } from "../../../../utils/overlay"

const ObnovleniyaBannerEnd = ({title, subtitle}) => (
    <div className={styles.wrapper}>
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>{subtitle}</div>
            </div>
            <button className={styles.btn} onClick={() => openOverlay( "hero", "trial")}>
                Попробовать бесплатно
            </button>
        </div>
    </div>
)

export default ObnovleniyaBannerEnd