import React from "react"
import styles from "./obnovleniya-desk.module.scss"
import UpdatesCard from "../obnovleniya-card"

const UpdatesDesk = ({ titleMain }) => (
    <div className={styles.container}>
        <div className={styles.title}>
            {titleMain}
        </div>
        <div className={styles.cardsWrapper}>
            <UpdatesCard 
                title = 'Заморозка ученика'
                descr='Новая функциональность, позволяющая скрывать учеников на время заморозки из расписания, а также автоматически продлевать их абонементы.'
                color='#6D20D2'
                blog={'https://paraplancrm.ru/blog/zamorozka-uchenika/'}
                image={require('../../../../images/obnovleniya/snowflake.svg')}
                arrow={require('../../../../images/obnovleniya/arrow-purple.svg')}
            />
            <UpdatesCard 
                title = 'Массовые действия'
                descr='После обновления у пользователей появилась возможность выбирать учеников из списка и выполнять с ними различные действия.'
                color='#03C4A4'
                blog={'https://paraplancrm.ru/blog/massovye-dejstviya-v-paraplan-crm'}
                image={require('../../../../images/obnovleniya/user-check.svg')}
                arrow={require('../../../../images/obnovleniya/arrow-green.svg')}
            />
            <UpdatesCard 
                title = 'Мультишаблоны'
                descr='Теперь можно создавать шаблон абонемента на несколько занятий.'
                color='#319DED'
                blog={'https://paraplancrm.ru/blog/multishablon/'}
                image={require('../../../../images/obnovleniya/layers.svg')}
                arrow={require('../../../../images/obnovleniya/arrow-blue.svg')}
            />
            <UpdatesCard 
                title = 'Рассылки и автоуведомления в WhatsApp'
                descr='Делайте рассылку в Whatsapp для быстрого оповещения учеников. Выгружайте данные о доходах и расходах в одном документе для анализа и отчетности.'
                color='#6D20D2'
                blog={'https://paraplancrm.ru/blog/whatsapp-rassylki-i-vygruzka-finansov'}
                image={require('../../../../images/obnovleniya/notification.svg')}
                arrow={require('../../../../images/obnovleniya/arrow-purple.svg')}
            />
            <UpdatesCard 
                title = 'Фискализация КОМТЕТ Касса'
                descr='Интеграция CRM-системы Параплан и КОМТЕТ Кассы. Автоматически фискализируйте платежи, совершенные через Интернет-эквайринг. Отправляйте чеки клиенту и в налоговую.'
                color='#03C4A4'
                blog={'https://paraplancrm.ru/blog/fiskalizaciya-platezhej'}
                image={require('../../../../images/obnovleniya/banknote.svg')}
                arrow={require('../../../../images/obnovleniya/arrow-green.svg')}
            />
            <UpdatesCard 
                title = 'Новые отчеты и показатели'
                descr='Мы добавили отчет по посещениям клиента, панель со статистическими данными по платежам клиента, информацию о сумме выплат сотруднику и показатель доходности занятия.'
                color='#319DED'
                blog={'https://paraplancrm.ru/blog/novye-otchety-i-pokazateli'}
                image={require('../../../../images/obnovleniya/chart.svg')}
                arrow={require('../../../../images/obnovleniya/arrow-blue.svg')}
            />
        </div>
    </div>
    
)

export default UpdatesDesk