import React from "react"
import { openOverlay } from "../../../../utils/overlay"
import styles from "./obnovleniya-banner.module.scss"


const ObnovleniyaBanner = ({ title }) => (
  <div className={styles.hero}>
    <div className={`container ${styles.container}`}>
      <h1 className={styles.heading}> {title} </h1>
      <a href="https://mrqz.me/66d07a81e794100026c22029" target="_blank">
          <button className={styles.cta}>
              Рассказать о бизнесе
          </button>
      </a>
    </div>
  </div>
)

export default ObnovleniyaBanner
