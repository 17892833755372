import React from "react"
import styles from "./obnovleniya-card.module.scss"

const UpdatesCard = ({ image, title, descr, blog, color, arrow}) => (
  <div className={styles.updates_card}>
        <div className={styles.image} style={{backgroundColor:color}}>
            <img src={image} />
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.descr}>{descr}</div>
        <div className={styles.blog} >
            <a href={blog} style={{color:color}} target="_blank">Узнать подробнее</a>
            <div className={styles.arrow}>
                <img src={arrow}/>
            </div>
            
        </div>
  </div>
)
export default UpdatesCard