import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroObnovleniyaParaplanCrm from "../components/pages/shared/hero/obnovleniya-paraplan-crm"
import Breadcrumb from '../components/controls/breadcrumbs';
import ObnovleniyaPageHead from "../components/pages/obnovleniya/obnovleniya-head"
import ObnovleniyaBanner from "../components/pages/obnovleniya/obnovleniya-banner"
import UpdatesDesk from "../components/pages/obnovleniya/obnovleniya-desk"
import ObnovleniyaBannerEnd from "../components/pages/obnovleniya/obnovleniya-banner-end"

const FranchisePage = (props) => {
    const crumbs = [
        { name: 'Главная', url: '/' },
        { name: 'Обновления Параплан CRM', url: '' }
    ];
    const supportlist = [
        { icon: require("../images/partners/support/tool.svg"), descr: 'Предложим готовые решения проблем' },
        { icon: require("../images/partners/support/partnership.svg"), descr: 'Поможем организовать работу в CRM под ваш бизнес' },
        { icon: require("../images/partners/support/support.svg"), descr: 'Ответим на любые вопросы по внедрению CRM' },
        { icon: require("../images/partners/support/settings.svg"), descr: 'Настроим интеграции вместе с вами ' },
        { icon: require("../images/partners/support/pay.svg"), descr: 'Продемонстрируем возможности системы' },
        { icon: require("../images/partners/support/trending-up.svg"), descr: 'Покажем способы увеличения эффективности' },
    ];
    return (
        <Layout headerColor={"#6D20D2"} page={"franchise"}>

            <SEO title="Обновления Параплан CRM | CRM-система учета для детских центров"
                description="Будьте в курсе наших планов и обновлений"
                keywords={[`Параплан обновления`, `разработка CRM-системы`]} url={"https://paraplancrm.ru" + props.location.pathname}
            />
            
            <HeroObnovleniyaParaplanCrm
                name={"franchise"}
                title='Обновления Параплан CRM'
                description='Поделитесь своими идеями по улучшению системы'
                color='#03C4A4'
            />
            <Breadcrumb crumbs={crumbs} arrowcolor={"purple"} />

            <ObnovleniyaPageHead 
                title='План обновлений CRM*'
                subtitle='В разработке Параплан CRM мы ориентируемся на отзывы и идеи пользователей, поэтому всегда понимаем, что необходимо сделать в первую очередь.'
                prompt='* Список может быть скорректирован.' />

            <ObnovleniyaBanner title='Расскажите нам о своем бизнесе, чтобы мы лучше понимали, какие функции вам нужны.' />

            <UpdatesDesk
                titleMain = "Последние обновления Параплан CRM"/>
            
            <ObnovleniyaBannerEnd
                title='Попробуйте Параплан CRM прямо сейчас'
                subtitle='Бесплатный доступ к полной версии на 10 дней!' />
        </Layout>
    )
}

export default FranchisePage
