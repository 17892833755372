import React from "react"
import styles from "./obnovleniya-paraplan-crm.module.scss"
import PresentationOverlayService from "../../../../../services/presentation-overlay-service"

const HeroObnovleniyaParaplanCrm = ({ title, description }) => (
  <div className={styles.hero}>

    <div className={`container ${styles.container}`}>
      <h1 className={styles.heading}>
        {title}
      </h1>
      <p className={styles.subheading}>{description}</p>
      <a href="https://mrqz.me/66d07a81e794100026c22029" target="_blank">
        <button className={styles.cta}>
          Предложить идею
        </button>
      </a>
      
    </div>

  </div>
)

export default HeroObnovleniyaParaplanCrm
